import firebase from 'firebase/app'

import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

if (process.env.NODE_ENV === 'production') {
  require('firebase/performance')
}

const config = {
  apiKey: 'AIzaSyCSExyya-L0XbcwJuTUgX40YWjfVviWX-w',
  authDomain: 'chunkspect.firebaseapp.com',
  databaseURL: 'https://chunkspect.firebaseio.com',
  projectId: 'chunkspect',
  storageBucket: 'chunkspect.appspot.com',
  messagingSenderId: '947400540095',
  appId: '1:947400540095:web:1aa3c0e5222a8af5',
}

firebase.initializeApp(config)

if (process.env.NODE_ENV === 'production') {
  firebase.performance()
}
