import React from 'react'
import ReactLoadable from 'react-loadable'

function Loading(props) {
  if (props.error) {
    return (
      <div>
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    )
  } else if (props.timedOut) {
    return (
      <div>
        Taking a long time... <button onClick={props.retry}>Retry</button>
      </div>
    )
  } else if (props.pastDelay) {
    return <div className="loader" />
  } else {
    return null
  }
}

export default function Loadable(opts) {
  return ReactLoadable(
    Object.assign(
      {
        loading: Loading,
        delay: 350,
        timeout: 10000,
      },
      opts,
    ),
  )
}
