import React, { lazy, useEffect, useState } from 'react'
import { Router } from '@reach/router'
import { auth } from 'firebase/app'

import Loadable from 'components/Loadable'

const Login = Loadable({
  loader: () => import('pages/Login' /* webpackChunkName: "Login" */),
})

const PublicBuild = Loadable({
  loader: () =>
    import('pages/PublicBuild' /* webpackChunkName: "PublicBuild" */),
})

const MainApp = Loadable({
  loader: () =>
    import('pages/MainApp' /* webpackChunkName: "MainApp", webpackPrefetch: true */),
})

function NotFound() {
  return <p>Sorry, nothing here</p>
}

export default function App() {
  return (
    <Router>
      <NotFound default />
      <Login path="/login" />
      <PublicBuild path="/builds/:buildId" />
      <MainApp path="/app/*" />
    </Router>
  )
}
